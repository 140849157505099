import React from 'react'
import ProjectTemplate from '../../components/Template/ProjectTemplate'
import LinkCard from '../../components/Cards/LinkCard'
import Seo from '../../components/Partials/Seo'

export default function TokopediaNextJs() {
  return (
    <ProjectTemplate>
      <Seo
        title="Tokopedia - Project"
        image="/images/tokopedia-cover.png"
      />
      <h1>Tokopedia Slicing Website</h1>
      <div className="mb-4">
        <img className="w-full" src="/images/projects/tokopedia-cover.png" alt="Dicka Ismaji Personal Website" />
        <div className="mt-4 p-4 border-2 border-red-600 rounded-xl">
          <LinkCard
            web={"https://tokped.vercel.app"}
            webName={"Demo"}
            github={"https://github.com/dicka88/tokopedia-clone"}
          />
        </div>
      </div>
      <section id="about" className="pt-4">
        <h2 className="font-bold">About</h2>
        <p>This is my updated personal web, after a few day after I buy this domain in then the website is launched</p>
        <p>This site is my personal website</p>
      </section>
      <section id="overview" className="pt-4">
        <h2 className="font-bold">Overview</h2>
        <p>According to Google Chrome Lighthouse, when i test website perfomance the result is below</p>
      </section>
      <section id="responsibility" className="pt-4">
        <h2 className="font-bold">Overview</h2>
        <p>How</p>
      </section>
      <section id="technology" className="pt-4">
        <h2 className="font-bold">Technology</h2>
        <p>React, NextJS, TailwindCSS, Swiper, React Loading Skeleton</p>
      </section>
    </ProjectTemplate>
  )
}